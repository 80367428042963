<template>
  <main class="persons-page page">
    <BreadcrumbsComponent title="Состав" :links="links" />
    <div class="container-padding">
      <div class="persons-page__inner">
        <PersonsMainComponent :person="minister" />
        <PersonsComponent
          v-if="first_vice_ministers && first_vice_ministers.length"
          :persons="first_vice_ministers"
          title="Первые заместители  Председателя Правительства"
        />
        <PersonsComponent
          v-if="vice_ministers && vice_ministers.length"
          :persons="vice_ministers"
          title="Заместители  Председателя Правительства"
        />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PersonsComponent from "../components/PersonsComponent.vue";
import PersonsMainComponent from "../components/PersonsMainComponent.vue";
import PERSONS_PAGE from "gql/pages/PersonsPage.graphql";

export default {
  name: "PersonsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: PERSONS_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("persons/save", data);
      })
      .catch(() => {});
  },
  computed: {
    first_vice_ministers() {
      return this.$store.state.persons.first_vice_ministers;
    },
    vice_ministers() {
      return this.$store.state.persons.vice_ministers;
    },
    minister() {
      return this.$store.state.persons.minister;
    },
  },
  data() {
    return {
      links: [
        {
          title: "Правительство",
          to: { name: "persons" },
        },
      ],
      person: {
        src: "/static/images/temp/person-main.png",
        title: "Абдулмуслимов Абдулмуслим Мухудинович",
        post: "Председатель Правительства РД",
        start_date: "с 22 февраля 2022 года",
        phone: "+7 (872) 896-89-89",
        email: "mail@mail.ru",
        socials: [],
      },
      persons: [
        {
          src: "/static/images/temp/person-1.png",
          title: "Алиев Руслан Алиевич",
          post: "Первый заместитель Председателя Правительства Республики Дагестан",
        },
        {
          src: "/static/images/temp/person-2.png",
          title: "Мажонц Манвел Людвигович",
          post: "Первый заместитель Председателя Правительства Республики Дагестан",
        },
      ],
      personsTwo: [
        {
          src: "/static/images/temp/person-3.png",
          title: "Джафаров Рамазан Джафарович",
          post: "Заместитель Председателя Правительства Республики Дагестан",
        },
        {
          src: "/static/images/temp/person-4.png",
          title: "Махмудов Абдурахман Магомедович",
          post: "Заместитель Председателя Правительства Республики Дагестан",
        },
      ],
    };
  },
  components: {
    PersonsMainComponent,
    PersonsComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.persons-page {

  &__inner {
    display flex
    flex-direction column
    gap 60px
    +below(768px) {
      gap 30px
    }
  }
}
</style>
