<template>
  <router-link :to="{ name: 'person', params: { id: person.id } }" class="person-component">
    <ImgComponent :img="person.img" class="person-component__image" />
    <div class="person-component__content">
      <span class="person-component__title">
        <span v-if="person.surname">{{ person.surname }}</span>
        <span v-if="person.name">{{ person.name }}</span>
        <span v-if="person.patronymic">{{ person.patronymic }}</span>
      </span>
      <span v-if="person['post']" class="person-component__post">{{ person["post"].title }}</span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "PersonComponent",
  props: {
    person: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.person-component {
  display flex
  flex-direction column
  max-width 320px
  max-height 620px

  &__image {
    min-height 407px
    max-height 407px
  }

  &__content {
    display flex
    flex-direction column
    gap 15px
    padding 30px
    background-color var(--white)
    height 100%
  }

  &__title {
    display flex
    flex-wrap wrap
    gap 5px

    span {
      font-weight 700
    }
  }

  &__post {
    font-size .875rem
    line-height 22px
  }
}
</style>
