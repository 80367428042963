<template>
  <div class="persons-component">
    <span class="persons-component__title">{{ title }}</span>
    <div class="persons-component__list">
      <PersonComponent v-for="(person, i) in persons" :key="i" :person="person" />
    </div>
  </div>
</template>

<script>
import PersonComponent from "./PersonComponent.vue";

export default {
  name: "PersonsComponent",
  props: {
    title: String,
    persons: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PersonComponent,
  },
};
</script>

<style lang="stylus">
.persons-component {
  display flex
  flex-direction column
  gap 40px
  +below(768px) {
    gap 20px
  }

  &__title {
    font-size 1.375rem
    font-weight 700
    line-height 32px
  }

  &__list {
    display flex
    flex-wrap wrap
    gap 40px
    +below(768px) {
      gap 20px
    }
  }
}
</style>
