<template>
  <div v-if="person" class="persons-main">
    <div class="persons-main__image">
      <router-link :to="{ name: 'person', params: { id: person.id } }" class="ratio-container">
        <ImgComponent :img="person.img" />
      </router-link>
    </div>
    <div class="persons-main__content">
      <div class="persons-main__text">
        <div></div>
        <router-link :to="{ name: 'person', params: { id: person.id } }" class="persons-main__title">
          <span v-if="person.surname">{{ person.surname }}</span>
          <span v-if="person.name">{{ person.name }}</span>
          <span v-if="person.patronymic">{{ person.patronymic }}</span>
        </router-link>
        <p v-if="person['post']">
          Должность:
          <span>{{ person["post"].title }}</span>
        </p>
        <p v-if="person.in_post_since">
          В должности:
          <span>{{ person.in_post_since }}</span>
        </p>
      </div>
      <div class="persons-main__text">
        <a v-if="person.phone" :href="`tel:${person.phone}`">
          Телефон:
          <span>{{ person.phone }}</span>
        </a>
        <a v-if="person.email" :href="`mailto:${person.email}`">
          Email:
          <span>{{ person.email }}</span>
        </a>
      </div>
      <div class="persons-main__socials" v-if="person.socials && person.socials.length">
        <a :href="social.link" target="_blank" v-for="(social, i) in person.socials" :key="i">
          <IconComponent category="default" :name="social.type.code | socialIconName" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "PersonsMainComponent",
  props: {
    person: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.persons-main {
  display grid
  grid-template-columns 560fr 800fr
  grid-gap 40px
  background-color var(--white)
  z-index 0
  +below(1024px) {
    grid-template-columns 1fr
    grid-gap 0
  }

  &__image {
    ratio(560, 560, contain)
  }

  &__content {
    display flex
    flex-direction column
    gap 30px
    padding 30px 0
    +below(1024px) {
      padding 30px
      gap 20px
    }
    +below(768px) {
      padding 15px
    }
  }

  &__text {
    display flex
    flex-direction column
    gap 15px
    +below(1024px) {
      gap 10px
    }
    +below(768px) {
      p, span {
        font-size .875rem
      }
    }
  }

  &__title {
    display flex
    gap 5px

    span {
      font-size 1.375rem
      font-weight 800
      line-height 27px
      +below(768px) {
        font-size 1.125rem
      }
    }
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px
    z-index 1

    a {
      background var(--white)
      border 1px solid var(--gray-dark)
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center

      .icon {
        max-width 20x
        max-height: 20px

        svg path {
          fill var(--dark)
        }
      }
    }
  }
}
</style>
